require("common/navigation");
require("common/common");

var init = function() {

	var maxResults = $('#maxResults').html();
	
	if(maxResults) {
		$('#maxResultsShow').html(maxResults);
		$('.doclist-count').show();
	} else {
		$('.doclist-count').hide();
	}

	$(".doclist-bot").hide();
	$(".dl-trig-hide").hide();

	$(".doclist-trig").click(function(){

		if (window.matchMedia("(min-width: 769px)").matches) {
			$(".dl-trig-hide").not($(this).find(".dl-trig-hide")).hide();
			$(".dl-trig-more").not($(this).find(".dl-trig-more")).show();
		};
		$(".dl-trig-icon-v").not($(this).find(".dl-trig-icon-v")).show();
		$(".doclist-block").not($(this).parent()).removeClass('active');

		var $this = $(this).parent().find(".doclist-bot");
		if (window.matchMedia("(min-width: 481px)").matches) {
			$(".doclist-bot").not($this).hide();
		}
		$this.toggle( "fast", function() {

		});
		if (window.matchMedia("(min-width: 769px)").matches) {
			$(this).find(".dl-trig-hide").toggle();
			$(this).find(".dl-trig-more").toggle();
		};
		$(this).find(".dl-trig-icon-v").toggle();
		$(this).parent().toggleClass('active');

	});
}

init();

$(".fks-themes .fks-title").click(function(){
	$(".subtheme").toggleClass('active');
	$(".subter").removeClass('active');
});

$(".fks-territoires .fks-title").click(function(){
	$(".subter").toggleClass('active');
	$(".subtheme").removeClass('active');
});

$(".rsel select").click(function(){
	$(".subter").removeClass('active');
	$(".subtheme").removeClass('active');
});

$(".doclist").click(function(){
	$(".subter").removeClass('active');
	$(".subtheme").removeClass('active');
});

$("#formSub").click(function(){
	$(".subter").removeClass('active');
	$(".subtheme").removeClass('active');
});

$('#unsubscribe-formular .uncheck, #subscribe-formular .uncheck').click(function(){
	$('ul.subtheme li input[type=checkbox]:checked').prop('checked', false);
});
$('#unsubscribe-formular .check, #subscribe-formular .check').click(function(){
	$('ul.subtheme li input[type=checkbox]').prop('checked', true);
});

var search = function(){
	$.ajax({
		type:     "GET",
		url:     pageURL,
		data:     'out=xml&page=1&'+$('.form-docs').serialize(),
		success:   displaySearch
	});
	return true;
}

var displaySearch = function  (xmlcontent) {
	if (xmlcontent.getElementsByTagName('data').length > 0) {
		$('.doclist-results').html(xmlcontent.getElementsByTagName('data').item(0).firstChild.nodeValue);
		init();
	}
}

$('#selectSortBy').change(function(e){
	search();
	e.preventDefault();
});

$('.form-docs').submit(function(e){
	search();
	e.preventDefault();
});

$('#topBtn').click(function () {
	$("body,html").animate({
		scrollTop: 0
	}, 800);
	return false;
});


// go to the top
function goOnTheTop() {
	$('a.top').click(function() {
		$('html, body').animate({scrollTop: 0}, 'slow');
		return false;
	});
}

// Print
function printJS() {
	if ($("a.print").length) {
		$("a.print").click(function() {
			print();
			return false;
		});
	}
}

// End Print
goOnTheTop();
printJS();
