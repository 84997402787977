
function sameHeight(selector){
    var elements = document.querySelectorAll(selector);
    var content = document.querySelectorAll(selector);
    var h = 0;

    Array.prototype.forEach.call(elements, function(el, i){
        if (el.offsetHeight > h) {
            h = el.offsetHeight;
        }
    });
    Array.prototype.forEach.call(content, function(el, i){
        if(h != 0){
            el.style.height = h+'px';
        }
    });
};

function widthLinkPdf(){
  var lienPdfWidth = $('.pmedia-left .lien-pdf').outerWidth();
  var lienPdfText = $('.pmedia-left .lien-pdf-text');
  lienPdfText.css('width', '');
  lienPdfText.css('width', lienPdfWidth);
}

$(document).ready(function() {
 widthLinkPdf();
    sameHeight('.subnav-lvl_2 a');
    if (window.matchMedia("(min-width: 769px)").matches)  {
       sameHeight('.ress-sh');
   };

});

$(window).on('resize',function() {
    if (window.matchMedia("(min-width: 481px)").matches) {
      /* La largeur minimum de l'affichage est 481px inclus */
      $('.subnav-lvl_2 a').css('height', '');
      sameHeight('.subnav-lvl_2 a');
      widthLinkPdf();
  }
});


