
$(document).click(function (e) {
	if(!$(e.target).closest('.epopup').length) {		
		if($('.epopup').hasClass('pop-spec')) {
			$('.epopup').removeClass('pop-spec');
		}	
	}
})


$('.espace-btn').click(function(e) { 
	e.stopPropagation();
	if(!$('.epopup').hasClass('pop-spec')) {
		$('.epopup').addClass('pop-spec');
	}
});


if( $('.connexion.switchoff').length ){
	$('.epopup-cont .password-link').hide();
	$('.epopup-cont .cms_forms').hide();
}

if( $('.epopup .cms_forms_error_msg').length ){
	$('.epopup').addClass('pop-spec');
}

// MOBILE NAV
$(document).ready(function(){
	$("#trigger-nav").click(function(){
		$(this).toggleClass('open');
		$('#mobile-nav').toggleClass('active');
		if ($('#mobile-nav').hasClass('active')) {
			//$('#mobile-nav').css('top', mobHeadH);
			$('html').addClass('lock-scroll');
		} else {
			//$('#mobile-nav').css('top', 0);
			$('html').removeClass('lock-scroll');
		}
	});
});

$('.mnav-blk ul').hide();

$('.mnav-about').click(function() {
	$('.mnav-about ul').toggle( "fast", function() {
		
	});
	$('.mnav-works ul').hide();
	$('.mnav-tools ul').hide();
});

$('.mnav-works').click(function() {
	$('.mnav-works ul').toggle( "fast", function() {
		
	});
	$('.mnav-about ul').hide();
	$('.mnav-tools ul').hide();
});

$('.mnav-tools').click(function() {
	$('.mnav-tools ul').toggle( "fast", function() {

	});
	$('.mnav-about ul').hide();
	$('.mnav-works ul').hide();
});

if (window.location.href.indexOf("#formAnchor3")) {
	  $("html, body").animate({ scrollTop: 0 });
}